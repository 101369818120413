export const uuidv4 = (): string => {
  if (typeof window.crypto.randomUUID !== 'undefined') {
    return window.crypto.randomUUID();
  }

  // for old browsers
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (char: string) => {
    const c = parseInt(char, 10);
    return (((c ^ crypto.getRandomValues(new Uint8Array(1))[ 0 ]) & 15) >> c / 4).toString(16);
  });
};
